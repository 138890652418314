import { environment as rivalEnvironment } from './environment.rival';

export const environment = {
  ...rivalEnvironment,

  rivalName: "winbig21",
  casinoId: 99,
  GA_TRACKING: "G-L8FBSVEHCG",

  rivalChatGroupName: "WinBig21",
  prettyName: "WinBig 21",
  contactEmail: "support@WinBig21.casino",
  docsEmail: "documents@WinBig21.casino",

  //SEO
  metaDescription: "Join WinBig 21 casino where online Gambling games, including top-tier slots, live dealer baccarat, and poker are waiting for you. Win big rewards, fast and safe payouts, play securely at our mobile optimized online casino. Deposit now for exclusive bonuses and daily promotions!",
  logoName: "win-big-21-online-casino-logo.png",
  logoAltText: "WinBig 21 Casino official logo, featuring bold yellow text with a red dice, representing an exciting online casino specializing in blackjack, slots, and table games. Experience secure and rewarding online gaming at WinBig 21 Casino."

};


